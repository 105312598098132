import React from "react";
import { Button, Tooltip, Typography } from "@mui/material";

interface DataGridToolbarButtonProps {
  title: string;
  handleOnClick: () => void;
  Icon: React.ElementType;
}

export const DataGridToolbarButton: React.FC<DataGridToolbarButtonProps> = ({
  title,
  handleOnClick,
  Icon,
}) => (
  <>
    <Tooltip title={title}>
      <Button onClick={handleOnClick}>
        <Icon fontSize="small" />
        <Typography fontSize="small">{title}</Typography>
      </Button>
    </Tooltip>
  </>
);
