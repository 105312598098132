import {
  DataGridPro,
  GridRowParams,
  GridRowsProp,
  GridColDef,
  GridCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React, { memo, useCallback, useMemo, VFC } from "react";
import { Box } from "@mui/material";
import clsx from "clsx";
import { useRecoilState } from "recoil";
import { UserState } from "../../../stores/UserState";
import { CustomGridStringOperators } from "../../atoms/CustomDataGridOperators";

interface Props {
  users: Paths.GetMasterdata.Responses.$200["users"];
  onClickRow: (sub: string) => void;
  handleRefresh: () => void;
}

const UserList: VFC<Props> = memo(({ users, onClickRow }) => {
  const [user] = useRecoilState(UserState);

  const rows: GridRowsProp = useMemo(() => users, [users]);
  const columns: GridColDef[] = useMemo(
    () => [
      { field: "sub", hideable: false, disableExport: true },
      {
        field: "company_code",
        headerName: "Company",
        headerAlign: "left",
        align: "center",
        width: 75,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "is_user_update",
        headerName: "MJ",
        headerAlign: "center",
        align: "center",
        width: 30,
        valueGetter: (value, row) => (row.is_user_update ? "Y" : ""),
        cellClassName: (params: GridCellParams<any, string>) =>
          clsx("is-alert", {
            positive:
              params.row.is_user_update && params.row.company_code !== "MJ",
          }),
      },
      {
        field: "name",
        headerName: "Name",
        valueGetter: (value, row): string =>
          `${row.given_name} ${row.family_name}`,
        minWidth: 250,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 220,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "regions",
        headerAlign: "left",
        headerName: "Regions",
        width: 75,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "primary_region",
        headerAlign: "left",
        headerName: "P.Region",
        width: 75,
        filterOperators: CustomGridStringOperators,
      },
    ],
    []
  );
  const handleRowClick = useCallback((id: GridRowParams["id"]) => {
    onClickRow(id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Box
        style={{ height: "100%", width: "100%" }}
        sx={{
          // Cell color setting
          "& .is-alert.positive": {
            fontWeight: "bold",
            color: "rgb(255, 0, 0)",
          },
          "& .is-login-user": {
            backgroundColor: "rgba(255, 99, 71, 0.1)",
            fontWeight: "bold",
          },
        }}
      >
        <DataGridPro
          style={{ fontSize: 11 }}
          rows={rows}
          rowHeight={30}
          columns={columns}
          hideFooter={true}
          getRowId={(row) => row.sub}
          onRowClick={(params: GridRowParams) => handleRowClick(params.id)}
          slots={{
            toolbar: CustomToolbar,
          }}
          density="compact"
          initialState={{
            columns: {
              columnVisibilityModel: { sub: false },
            },
            filter: {
              filterModel: {
                items: [
                  { field: "company_code", operator: "equals", value: "" },
                ],
              },
            },
          }}
          getRowClassName={(params) =>
            user?.sub === params.row.sub ? "is-login-user" : ""
          }
        />
      </Box>
    </>
  );
});

export default UserList;
