import React, { useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import HelpIcon from "@mui/icons-material/Help";
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache";
import { useSWRConfig } from "swr";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../../stores/SnackbarState";
import { UserState } from "../../../stores/UserState";
import HasUpdateAuthority from "../../../functions/HasUpdateAuthority";

type success = Paths.GetMemorandum.Responses.$200;
type error = Paths.GetMemorandum.Responses.Default;

const Memorandum = () => {
  const [value, setValue] = useState<string | undefined>("");
  const [inEdit, setInEdit] = useState(false);
  const [error, setError] = useState(false);
  const [snackbar, setSnackbar] = useRecoilState(SnackbarState);

  const { mutate } = useSWRConfig();
  const postMemorandum = useApiPost<
    | Paths.UpdateMemorandum.Responses.$200
    | Paths.UpdateMemorandum.Responses.Default
  >("memorandum");
  const { data, url } = useApiGetWithCache<success | error>(
    "stm",
    "memorandum",
    {}
  );
  const [user] = useRecoilState(UserState);

  useEffect(() => {
    if (!inEdit && data) {
      if ("memorandum" in data) {
        setValue(data.memorandum);
        setError(false);
      } else {
        setError(true);
        setValue("# Failed to fetch data.   \nPlease refresh!");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClickEdit = () => setInEdit(true);
  const handleClickSave = () => {
    const body: Paths.UpdateMemorandum.Parameters.Memorandum = {
      memorandum: value || "",
    };
    postMemorandum({ body }).then((r) => {
      mutate(url, { ...(data as success), memorandum: value });
      setSnackbar({
        ...snackbar,
        open: true,
        message: r.message,
        severity: r.code === 200 ? "success" : "error",
      });
      mutate(url);
      setInEdit(false);
    });
  };

  const handleOnChange = (e: string | undefined) => setValue(e);
  return (
    <Box mb={2}>
      <Box
        pl={2}
        sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
      >
        <Typography fontWeight={"bold"}>Memo</Typography>
        <Tooltip arrow title="Memo is shared in your company.">
          <HelpIcon fontSize="small" color="disabled" />
        </Tooltip>
        <Box pl={2}>
          {!error &&
            (inEdit ? (
              <IconButton color="primary" onClick={handleClickSave}>
                <SaveIcon />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                onClick={handleClickEdit}
                disabled={HasUpdateAuthority(
                  user?.is_user_update,
                  user?.company_code
                )}
              >
                <EditIcon />
              </IconButton>
            ))}
        </Box>
      </Box>
      <Box boxShadow={1} padding={3} borderRadius={2} mt={1}>
        {inEdit ? (
          <div data-color-mode="light">
            <MDEditor
              value={value}
              onChange={handleOnChange}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
            />
          </div>
        ) : (
          <div data-color-mode="light">
            <MDEditor.Markdown
              source={value}
              rehypePlugins={[[rehypeSanitize]]}
            />
          </div>
        )}
      </Box>
    </Box>
  );
};
export default Memorandum;
